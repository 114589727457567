import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { AuthProvider } from './providers/IAuthProvider';

@Injectable()
export class CanActivateUserRoute {
  private authProvider = inject(AuthProvider);
  private router = inject(Router);

  canActivate(): boolean | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | UrlTree {
    if (this.authProvider.isAuthenticatedValue) {
      return true;
    } else {
      void this.router.navigate(['/search']);
      return false;
    }
  }
}
