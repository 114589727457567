import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { GaardskartService } from '@services/gaardskart.service';
import { ParamService } from '@services/param.service';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable()
export class MapResolver {
  private gaardskartService = inject(GaardskartService);
  private paramService = inject(ParamService);
  private router = inject(Router);

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const properties = this.paramService.parseUrl(route.params, route.data['grunneiendom']);
    if (!properties) {
      void this.router.navigate(['/search']);
      return of(null);
    }
    // We do accept both OK and OLD_DATA from route landbrukseiendom
    let responseStates = ['OK', 'OLD_DATA'];
    // we have not implemented use of cache for grunneiendom
    if (route.data['grunneiendom']) {
      responseStates = ['OK'];
    }
    return this.gaardskartService.getProperties(properties, route.data['grunneiendom'], responseStates).pipe(
      catchError(() => {
        void this.router.navigate(['/search']);
        return of(null);
      }),
      take(1),
    );
  }
}
