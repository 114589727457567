import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationSkipped,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { EndPointService } from '@services/endpoint.service';
import { environment } from 'environments/environment';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  imports: [RouterOutlet, SpinnerComponent],
  selector: 'gk-routing',
  standalone: true,
  styleUrls: ['./routing.component.scss'],
  templateUrl: './routing.component.html',
})
export class RoutingComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private eps = inject(EndPointService);
  private router = inject(Router);
  private titleService = inject(Title);

  env: any;
  loading = false;

  cancelRouting() {
    this.loading = false;
    this.eps.cancelRequests();
  }

  ngOnInit() {
    this.env = environment;
    // code to change page title dynemically
    this.router.events
      .pipe(
        tap(event => {
          if (
            event instanceof NavigationCancel ||
            event instanceof NavigationEnd ||
            event instanceof NavigationSkipped ||
            event instanceof NavigationStart
          ) {
            this.loading = false;
          }
        }),
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
      });
  }
}
