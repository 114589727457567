/// <reference types="@angular/localize" />

import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeNb from '@angular/common/locales/nb';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { TreeviewModule } from '@ccondrup/ngx-treeview';
import { ApiConfig } from '@config/api.config';
import { DomChangeDirective } from '@directives/dom-change.directive';
import { StoreModule } from '@ngrx/store';
import { StoreSetup } from '@reducers/store-setup';
import { AreaStatisticsService } from '@services/area-statistics.service';
import { ConfigService } from '@services/config.service';
import { DrawService } from '@services/draw.service';
import { DrawLayerService } from '@services/draw-layer.service';
import { EndPointService } from '@services/endpoint.service';
import { ErrorService } from '@services/error.service';
import { GaardskartService } from '@services/gaardskart.service';
import { ImportedFileMapService } from '@services/imported-file-maps.service';
import { BackgroundMapsService } from '@services/index';
import { LayerTreeService } from '@services/layer-tree.service';
import { LegendService } from '@services/legend.service';
import { MapResolver } from '@services/map.resolver';
import { MapService } from '@services/map.service';
import { MapCanvasService } from '@services/map-canvas.service';
import { ParamService } from '@services/param.service';
import { PrintService } from '@services/print.service';
import { PrintMapService } from '@services/print-map.service';
import { PropertyService } from '@services/property.service';
import { RouteReuseService } from '@services/route-reuse-service';
import { TicketService } from '@services/ticket.service';
import { UserService } from '@services/user.service';
import { UserDataService } from '@services/user-data.service';
import { AngularSplitModule } from 'angular-split';
import { CanActivateUserRoute } from 'app/auth/CanActivateUserRoute';
import { AuthInterceptor } from 'app/auth/interceptor/AuthInterceptor';
import { BackendAuthClient } from 'app/auth/providers/BackendAuthClient';
import { AuthProvider } from 'app/auth/providers/IAuthProvider';
import { GkRoutingModule } from 'app/gk.routes.module';
import { I18n } from 'app/own-layers/i18n';
import { RoutingComponent } from 'app/routing/routing.component';
import { environment } from 'environments/environment';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

registerLocaleData(localeNb);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RoutingComponent, {
  providers: [
    importProvidersFrom(
      AccordionModule,
      AlertModule.forRoot(),
      AngularSplitModule,
      BrowserModule,
      FormsModule,
      GkRoutingModule,
      ModalModule.forRoot(),
      PopoverModule.forRoot(),
      ReactiveFormsModule,
      RouterModule,
      StoreModule.forRoot(StoreSetup, {
        runtimeChecks: {
          strictActionImmutability: false,
          strictStateImmutability: false,
        },
      }),
      TooltipModule.forRoot(),
      TreeviewModule.forRoot(),
      TypeaheadModule.forRoot(),
      environment.imports,
      TabsModule,
      TypeaheadModule,
      AlertModule,
      PopoverModule,
    ),
    ApiConfig,
    AreaStatisticsService,
    BackgroundMapsService,
    CanActivateUserRoute,
    ConfigService,
    DomChangeDirective,
    DrawLayerService,
    DrawService,
    EndPointService,
    ErrorService,
    GaardskartService,
    ImportedFileMapService,
    LayerTreeService,
    LegendService,
    MapCanvasService,
    MapResolver,
    MapService,
    ParamService,
    PrintMapService,
    PrintService,
    PropertyService,
    TicketService,
    UserDataService,
    UserService,
    {
      deps: [Router, HttpClient, BsModalService, DOCUMENT, MapCanvasService, UserService],
      provide: AuthProvider,
      useClass: BackendAuthClient,
    },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
    { provide: LOCALE_ID, useValue: 'nb' },
    I18n,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch(err => console.error(err));
