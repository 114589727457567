import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class RouteReuseService implements RouteReuseStrategy {
  private bAttach = false;
  private bDetach = false;
  private handlers: Record<string, DetachedRouteHandle> = {};

  private kart = ['landbrukseiendom/:komm/:gnr/:bnr/:fnr', 'grunneiendom/:komm/:gnr/:bnr/:fnr'];
  private minSide = ['min-side'];

  // In shouldReuseRoute you need to check the child to get the URL.
  getUrl(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig) {
      return route.routeConfig.path;
    }
    if (route.firstChild) {
      return this.getUrl(route.firstChild);
    }
    return '';
  }

  // Retrieves a route from memory.
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return null;
    }
    return this.handlers[this.getUrl(route)];
  }

  // Determines whether a route should be retrieved from memory and used.
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const url = this.getUrl(route);

    if (this.bAttach) {
      return !!this.handlers[url];
    } else {
      return false;
    }
  }

  // If you are going from min side to kart, attach.
  shouldAttachCheck(from, to) {
    return this.minSide.includes(from) && this.kart.includes(to);
  }

  // Determines whether a route should be used.
  // WARNING!
  // It fires twice when changing route. First with (route you are going to, route you are on).
  // Then with (route you are on, route you are going to).
  // Determines whether a route should be stored in memory for later reuse.
  shouldDetach(): boolean {
    return this.bDetach;
  }

  // Get the URL based on route snapshot.
  // If you are going from kart to min side, detach.
  shouldDetachCheck(from, to) {
    return this.kart.includes(from) && this.minSide.includes(to);
  }

  // Determines whether you should detach the route for later use.
  // Then it checks the other methods in the service.
  shouldReuseRoute(current: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot): boolean {
    const currentUrl = this.getUrl(current);
    const futureUrl = this.getUrl(future);
    this.bAttach = this.shouldAttachCheck(currentUrl, futureUrl);
    this.bDetach = this.shouldDetachCheck(currentUrl, futureUrl);

    const reUseUrl = false;
    const defaultReuse = future.routeConfig === current.routeConfig;
    return reUseUrl || defaultReuse;
  }

  // Determines whether you should attach a route from memory.
  // Stores the route in memory.
  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    if (handler) {
      this.handlers[this.getUrl(route)] = handler;
    }
  }
}
