import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapResolver } from '@services/map.resolver';
import { CanActivateUserRoute } from 'app/auth/CanActivateUserRoute';

const routes: Routes = [
  {
    data: { title: 'Søk gård' },
    loadComponent: () => import('app/front-page/front-page.component').then(m => m.FrontPageComponent),
    path: 'search',
  },
  {
    data: { grunneiendom: false, title: 'Gårdskart - NIBIO' },
    loadComponent: () => import('app/gk.component').then(m => m.GkComponent),
    path: 'landbrukseiendom/:komm/:gnr/:bnr/:fnr',
    resolve: { propertyData: MapResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    data: { grunneiendom: true, title: 'Gårdskart - NIBIO' },
    loadComponent: () => import('app/gk.component').then(m => m.GkComponent),
    path: 'grunneiendom/:komm/:gnr/:bnr/:fnr',
    resolve: { propertyData: MapResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    loadComponent: () => import('app/auth/callback/AuthCallback').then(m => m.AuthCallbackComponent),
    path: 'signInCallback',
  },
  {
    loadComponent: () => import('app/auth/signout/signout.component').then(m => m.SignoutComponent),
    path: 'signOutCallback',
  },
  {
    canActivate: [CanActivateUserRoute],
    data: { title: 'Bruker registrering' },
    loadComponent: () =>
      import('app/user-registration/registration-page/registration-page.component').then(
        m => m.UserRegistrationPageComponent,
      ),
    path: 'bruker-registrering',
  },
  {
    canActivate: [CanActivateUserRoute],
    data: { title: 'Min side' },
    loadComponent: () =>
      import('app/bruker-info/min-side-page/min-side-page.component').then(m => m.MinSidePageComponent),
    path: 'min-side',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/search',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/search',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { useHash: false })],
})
export class GkRoutingModule {}
